export const players = [
  "Mugi",
  "Cilvet",
  "Inamol",
  "Rebe",
  "Malumbres",
  "Josebas",
  "Kinüs",
  "Markelinho",
  "Centeno",
  "Guille",
  "Layson",
  "Beorn",
  "Jonvi",
];
