import React, { FC, useEffect, useRef, useState } from "react";
import logo from "./logo.svg";
import "./index.css";
import image from "./2018121912275545_1.jpeg";
import linkgif from "./playerAssets/gifs/beorn.gif";
import ReactPlayer from "react-player";

// import oliLogo from "./oliLogo.png";
import oliLogo from "./oliMario-trimmy.png";
import oliCara from "./oliCara.png";
// import mikelLogo from "./mikelLogo.png";
import mikelLogo from "./mikelPeach-trimmy.png";
import mikelCara from "./mikelCara.png";

import useSound from "use-sound";
import boxSelectedSound from "./menu.mp3"; // Your sound file path here
import backgroundMusic from "./music.mp3"; // Your sound file path here
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { useFocusable } from "@noriginmedia/norigin-spatial-navigation";
import { useKeyPress } from "./useKeyPress";
import { players } from "./players";

const playersWithStuff = players.map((p) => {
  return {
    player: p,
    image: `playerAssets/images/${p.toLocaleLowerCase().replace("ü", "u")}.png`,
    gif: `playerAssets/gifs/${p.toLocaleLowerCase().replace("ü", "u")}.gif`,
    audio: new Audio(
      `playerAssets/audios/${p.toLocaleLowerCase().replace("ü", "u")}.wav`
    ),
  };
});

function App() {
  const [remainingPlayers, setRemainingPlayers] = useState(players);
  const [oliPlayers, setOliPlayers] = useState<string[]>([]);
  const [mikelPlayers, setMikelPlayers] = useState<string[]>([]);
  const [playBoxSelected] = useSound(boxSelectedSound);
  const [playMusicLoop, exposedData] = useSound(backgroundMusic, {
    loop: true,
    volume: 0.2
  });
  const [focusedPlayer, setFocusedPlayer] = useState(players[0]);
  const [oliSelecting, setOliSelecting] = useState(false);

  const [playingIntro, setPlayingIntro] = useState(false);
  const [videoFinished, setVideoFinished] = useState(false);

  const playerRef = useRef<ReactPlayer>(null);
  const handle = useFullScreenHandle();

  const [enterPressed, setEnterPressed] = useState(false);
  const [firstTime, setFirstTime] = useState(true);

  const enterPress = useKeyPress("Enter");
  const skipPress = useKeyPress("s");

  const {ref: backRef} = useFocusable({
    focusKey: 'back',
    autoRestoreFocus: true,
  });

  useEffect(() => {
    setEnterPressed(enterPress);
  }, [enterPress]);

  useEffect(() => {
    if (skipPress) setVideoFinished(true);
  }, [skipPress]);

  const playerSelected = (player: string, oliSelecting: boolean) => {
    playersWithStuff.find((p) => p.player === player)?.audio.play();
    if (oliSelecting) {
      setOliPlayers([...oliPlayers, player]);
    } else {
      setMikelPlayers([...mikelPlayers, player]);
    }
    setOliSelecting(!oliSelecting);

    setRemainingPlayers([...remainingPlayers.filter((p) => p !== player)]);
  };

  useEffect(() => {
    if (enterPress && !enterPressed) {
      playerSelected(focusedPlayer, oliSelecting);
    }
  }, [enterPress, enterPressed, focusedPlayer, oliSelecting]);

  const undo = (oliSelecting: boolean) => {
    let latestPlayerAdded: string | undefined;
    if (!oliSelecting) {
      let currentOliplayers = [...oliPlayers];
      latestPlayerAdded = currentOliplayers.pop();
      setOliPlayers(currentOliplayers);
    } else {
      let currentMikelPlayers = [...mikelPlayers];
      latestPlayerAdded = currentMikelPlayers.pop();
      setMikelPlayers(currentMikelPlayers);
    }
    if (latestPlayerAdded) {
      setOliSelecting(!oliSelecting);
      setRemainingPlayers([...remainingPlayers, latestPlayerAdded!]);
    }
  };

  // useEffect(() => {
  //   if (playerRef.current) {
  //     playerRef.current.getInternalPlayer
  //     // @ts-ignore
  //       ?.requestFullscreen()
  //       .catch((err: any) => {
  //         console.log(err)
  //       });
  //   }
  // }, [playerRef]);

  useEffect(() => {
    if (!remainingPlayers.length) {
      exposedData.pause();
    }
  }, [remainingPlayers, exposedData]);

  useEffect(() => {
    if (videoFinished) {
      playMusicLoop();
    }
  }, [playMusicLoop, videoFinished]);

  if (!videoFinished) {
    return (
      <div className="w-full">
        {/**@ts-ignore */}
        <FullScreen handle={handle}>
          <div>
            <ReactPlayer
              url="https://vimeo.com/704339026?playsinline=0 "
              controls={false}
              width={window.innerWidth}
              height={window.innerHeight}
              ref={playerRef}
              playing={playingIntro}
              onEnded={() => setVideoFinished(true)}
            />

            {!playingIntro && (
              <button
                className="h-10 bg-colorMikel text-white rounded p-6 pb-10 absolute right-1/2 top-1/2"
                onClick={() => {
                  setPlayingIntro(!playingIntro);
                }}
              >
                <h1
                  className={`text-3xl font-bold text-center text-white font-mariokart mr-3 animate-bounce`}
                >
                  play
                </h1>
              </button>
            )}
          </div>
        </FullScreen>
      </div>
    );
  }
  interface PersonajeProps {
    player: string;
  }

  const Personaje: FC<PersonajeProps> = ({ player }) => {
    const { ref, focused, focusSelf } = useFocusable({
      focusKey: player,
      autoRestoreFocus: true,
    });

    // useEffect(() => {
    //   if (player === remainingPlayers[0]) {
    //     focusSelf();
    //   }
    // }, [remainingPlayers, focusSelf]);

    useEffect(() => {
      if (focused) {
        playBoxSelected();
        setFocusedPlayer(player);
      }
    }, [focused]);

    return (
      <div
        className={`w-3/12 grow-0 ${focused ? "p-0" : "p-2"}`}
        ref={ref}
        onKeyDown={(e) => {
          console.log(e);
        }}
      >
        <div
          className={`cursor-pointer ${
            focused ? "border-4 border-yellow-300" : ""
          } `}
        >
          {focused ? (
            <img src={playersWithStuff.find((p) => p.player === player)?.gif} />
          ) : (
            <img
              src={playersWithStuff.find((p) => p.player === player)?.image}
            />
          )}
          <h1 className="text-2xl font-bold text-center mt-3 text-white font-mariokart">
            {player}
          </h1>
        </div>
      </div>
    );
  };

  const SeleccionPersonajes = () => {
    const {
      ref: parentRef,
      focusSelf,
      setFocus,
      hasFocusedChild,
    } = useFocusable({
      autoRestoreFocus: true,
      preferredChildFocusKey: remainingPlayers.length
        ? remainingPlayers[0]
        : undefined,
      trackChildren: true,
    });

    useEffect(() => {
      if (!remainingPlayers.includes(focusedPlayer) || firstTime) {
        if (firstTime) {
          setFirstTime(false);
        }

        remainingPlayers.length && setFocus(remainingPlayers[0]);
      }
    }, [firstTime, hasFocusedChild, remainingPlayers, focusedPlayer]);

    return (
      <div className="align-top p-1 ml-14 mr-14 w-full">
        <div className="inline-flex w-full justify-center">
          <h1
            className={`text-3xl font-bold text-center mb-3 mt-3 ${
              oliSelecting ? "text-colorOli" : "text-colorMikel"
            } font-mariokart mr-3 animate-bounce`}
          >
            {oliSelecting ? "Oli, " : "Mikel, "}
          </h1>
          <h1 className="text-3xl font-bold text-center mb-3 mt-3 text-white font-mariokart">
            {" Elije tu personaje"}
          </h1>
        </div>

        <div className="flex flex-wrap w-full" ref={parentRef}>
          {remainingPlayers.map((player) => (
            <Personaje player={player} />
          ))}
        </div>
        <div className="flex justify-center " ref={backRef}>
          <div
            className="pl-10 pr-10 pt-2 pb-2 rounded bg-yellow-500"
            onClick={() => undo(oliSelecting)}
            ref={backRef}
          >
            <h1 className="text-3xl font-bold text-center mb-3 mt-3 text-white font-mariokart">
              {"ATRÁS"}
            </h1>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="min-h-screen">
      <div className="flex min-h-screen">
        {/** OLI */}
        <div className="w-1/6 flex-shrink-0 relative">
          {(oliSelecting || !remainingPlayers.length) && (
            <div className="bg-colorOli w-full h-full -skew-x-3 absolute top-0 ml-10 border-r-8 border-yellow-300"></div>
          )}
          <div className="bg-colorOli w-full h-full absolute">
            {/* <h2 className="text-3xl font-bold text-center mb-3 mt-3 text-white font-mariokart">
              {"Oli"}
            </h2> */}
            <div
              className={`flex justify-end relative ${
                oliSelecting ? "pt-4" : ""
              }`}
            >
              <img
                src={oliCara}
                className={`rounded-full ${
                  oliSelecting || !remainingPlayers.length
                    ? "w-full mt-10 animate-bounce"
                    : "w-1/2 m-4"
                }
                `}
              />
              <img
                src={oliCara}
                className={`rounded-full absolute ${
                  oliSelecting || !remainingPlayers.length
                    ? "w-full mt-10 animate-bounce"
                    : "w-1/2 m-4"
                }
                `}
              />
              <img
                src={oliLogo}
                className={`absolute rounded-full border-white border-8 ${
                  oliSelecting || !remainingPlayers.length
                    ? "w-1/2 animate-spin"
                    : "w-1/4 m-4"
                }
              `}
              />
            </div>
            {oliPlayers.map((player) => (
              <h1 className="text-3xl font-bold text-center mb-3 mt-3 text-white font-mariokart">
                {player}
              </h1>
            ))}
          </div>
        </div>
        {/** seleccion */}
        {remainingPlayers.length ? (
          <SeleccionPersonajes />
        ) : (
          <ReactPlayer
            url="https://vimeo.com/704362310?playsinline=0 "
            controls={false}
            width={window.innerWidth}
            height={window.innerHeight}
            ref={playerRef}
            playing={true}
            onEnded={() => setVideoFinished(true)}
          />
        )}
        {/** KELMI */}
        <div className="w-1/6 flex-shrink-0 relative">
          {(!oliSelecting || !remainingPlayers.length) && (
            <div
              className={`bg-colorMikel w-full h-full skew-x-3 absolute top-0 -ml-10 border-l-8 border-yellow-300`}
            ></div>
          )}
          <div className="bg-colorMikel w-full h-full absolute">
            <div
              className={`flex justify-start relative ${
                oliSelecting ? "pt-4" : ""
              }`}
            >
              <img
                src={mikelCara}
                className={`rounded-full ${
                  !oliSelecting || !remainingPlayers.length
                    ? "w-full mt-10 animate-bounce"
                    : "w-1/2 m-4"
                }
                `}
              />
              <img
                src={mikelCara}
                className={`rounded-full absolute ${
                  !oliSelecting || !remainingPlayers.length
                    ? "w-full mt-10 animate-bounce"
                    : "w-1/2 m-4"
                }
                `}
              />
              <img
                src={mikelLogo}
                className={`absolute rounded-full border-white border-8 ${
                  !oliSelecting || !remainingPlayers.length
                    ? "w-1/2 animate-spin"
                    : "w-1/4 m-4"
                }
              `}
              />
            </div>
            {mikelPlayers.map((player) => (
              <h1 className="text-3xl font-bold text-center mb-3 mt-3 text-white font-mariokart">
                {player}
              </h1>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
